import React,{useEffect, useState} from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import PostList from "../../components/PostList";
import *as styles from "./{strapiCategory.slug}.module.css";
import SEO from "../../components/SEO";
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redifine classes here, if you want.
  activeClassName: 'active', // default to "disabled"
})`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  color: #ffc107;
  li a {
    border-radius: 50px;
    padding: 0.3rem 0.6rem;
    border: gray 1px solid;
    cursor: pointer;
    
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    text-decoration: none;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const CategoryPage = (props) => {
  const category = props.data.strapiCategory;
  const posts = props.data.allStrapiPost.nodes;
  const [currentPosts, setCurrentPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentPosts(posts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(posts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length;
    setItemOffset(newOffset);
  };


  return (
    <Layout>
      <SEO title={category.name} description={category.name} />
      <div className={styles.container}>
        <p className={styles.categoryName}> {category.viewName}</p>
        <div className={styles.main}>
          <PostList posts={currentPosts} />
        </div>
        <div className={styles.paginat}>
        <MyPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query ($slug: String){
  strapiCategory (slug: { eq: $slug }){
    name
    slug
  }
  allStrapiPost(filter: {category: {slug: {eq: $slug}}}) {
      nodes {
        slug
        title
        video
        description
        thumbnail
        published_at(formatString:  "YYYY年MM月DD日 hh:mm")
        category {
          slug
          name
          viewName
        }
      }
    }
  }
`

export default CategoryPage;
